<template>
  <span
    class="mr-auto cursor-pointer"
    @click="authStore.stage = authStage.WITHOUT_PASSWORD"
  >
    <div class="flex flex-row">
      <UiIcon name="arrowCircle" />
      <p class="ml-2">Назад</p>
    </div>
  </span>
  <div class="flex flex-col">
    <p class="text-center text-2xl lg:text-3xl">Введите код</p>
    <p class="mt-3 text-center text-sm lg:text-lg">
      Код отправлен в СМС на номер {{ authStore.phoneNumber }}
    </p>
  </div>
  <div class="flex flex-col">
    <span
      v-if="v$.smsCode.$error"
      class="text-dnd_red mb-5 text-center"
    >
      Указаны некорректные данные
    </span>
    <UiPhoneCode
      v-model="smsCode"
      :error="v$.smsCode.$error"
    />
  </div>
  <div class="flex w-full flex-col gap-3 lg:gap-4">
    <UiButton
      class="bg-dnd_light_yellow"
      @click="goToLong()"
    >
      Продолжить
    </UiButton>
    <p class="text-center text-sm lg:lg:text-base">
      Получить новый SMS-код через
      <span class="text-dnd_yellow">1:00</span>
    </p>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { authStage } from '~/helpers/auth';
import { AuthRules } from '~/helpers/validator';

const authStore = useAuthStore();

const authRules = AuthRules(authStore);
const smsCode = ref('');

const v$ = useVuelidate(authRules, { smsCode });

// const goToWithoutPassword = () => {
//   authStore.stage = 'withoutPassword';
// };

const goToLong = async () => {
  if (await v$.value.$validate()) {
    navigateTo('/long');
  }
};
</script>
