<template>
  <article
    v-if="isLoading"
    aria-label="authorization"
    class="mx-auto my-10 flex max-w-[360px] flex-col justify-center gap-10 md:max-w-[480px] lg:my-20 lg:max-w-[640px]"
  >
    <div class="mx-2 rounded-[40px] bg-white">
      <div
        class="mx-4 my-10 flex flex-col items-center justify-center gap-y-8 lg:mx-14 lg:my-16 lg:gap-y-10"
      >
        <UiIsLoading />
      </div>
    </div>
  </article>
  <Auth v-else />
</template>

<script setup>
definePageMeta({
  layout: 'empty',
});

const { $login } = useNuxtApp();

const route = useRoute();
const userStore = useUserStore();
const notieStore = useNotieStore();
const dataForLongStore = useDataForLongStore();

const isLoading = ref(false);

onMounted(async () => {
  try {
    if (route.query.token && import.meta.client) {
      isLoading.value = true;
      // emitter.emit('switchLoader', true);
      dataForLongStore.order_id = route.query.order_id;
      dataForLongStore.mobile_phone = route.query.mobile_phone;
      userStore.user.phone = route.query.mobile_phone;

      const names = {
        last_name: route.query.last_name,
        first_name: route.query.first_name,
      };
      if (route.query.secondary_name) {
        names.secondary_name = route.query.secondary_name;
      }
      await $login({ token: route.query.token, path: '/long', names });
    }
  } catch (err) {
    if (err.response?.data?.message) {
      notieStore.addNotification({
        type: 'error',
        message: err.response.data.message,
      });
    }
    isLoading.value = false;
  } finally {
    // emitter.emit('switchLoader', false);
  }
});
</script>

<style scoped></style>
