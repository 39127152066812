<template>
  <span
    class="mr-auto cursor-pointer"
    @click="authStore.stage = authStage.WITH_PASSWORD"
  >
    <div class="flex flex-row">
      <UiIcon name="arrowCircle" />
      <p class="ml-2">Назад</p>
    </div>
  </span>
  <h1 class="text-dnd_black text-2xl lg:text-3xl">Регистрация</h1>
  <div class="flex w-full flex-col gap-3 lg:gap-4">
    <span
      v-if="v$.phoneNumber.$error || v$.password.$error || v$.confirmPassword.$error"
      class="text-dnd_red mb-5 text-center"
    >
      Указаны некорректные данные
    </span>
    <UiInput
      v-model="authStore.phoneNumber"
      title="Номер телефона"
      data-maska="+7 (9##) ### ##-##"
      :error="v$.phoneNumber.$error"
    />
    <UiInput
      v-model="password"
      title="Пароль"
      type="password"
      :error="v$.password.$error"
    />
    <UiInput
      v-model="confirmPassword"
      title="Повторите пароль"
      type="password"
      :error="v$.confirmPassword.$error"
    />
    <UiButton
      type="primary"
      :disabled="disabledBtn"
      @click="goToLong()"
    >
      Продолжить
    </UiButton>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { authStage } from '~/helpers/auth';
import { AuthRules } from '~/helpers/validator';

const authStore = useAuthStore();

const { phoneNumber } = storeToRefs(authStore);

const password = ref('');
const confirmPassword = ref('');
const authRules = AuthRules({
  stage: authStore.stage,
  password,
});

const disabledBtn = computed(() => v$.value.$invalid);

const v$ = useVuelidate(authRules, {
  phoneNumber,
  password,
  confirmPassword,
});

// const goToLong = async () => {
//   if (await v$.value.$validate()) {
//   }
// };
</script>
