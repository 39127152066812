<template>
  <article
    aria-label="authorization"
    class="my-10 flex max-w-[360px] flex-col justify-center gap-10 md:max-w-[480px] lg:my-10 2xl:my-20 lg:max-w-[640px]"
  >
    <div class="mx-2 rounded-[40px] bg-white">
      <div
        class="mx-4 my-10 flex flex-col items-center justify-center gap-y-8 lg:mx-14 lg:gap-y-10"
      >
        <component :is="component"></component>

        <!-- <hr class="w-full" /> -->
        <!-- Backend 3.0 -->
        <!-- <div class="flex w-full flex-col gap-3 md:flex-row lg:flex-col lg:gap-4"> -->
        <!-- <div class="md:w-full">
            <UiButton type="gosuslugi">Госуслуги</UiButton>
          </div>
          <div class="md:w-full">
            <UiButton type="tinkoff">Тинькофф</UiButton>
          </div> 
        </div>-->
        <span
          v-if="authStore.stage === authStage.WITHOUT_PASSWORD"
          class="text-3 lg:text-4 cursor-pointer"
          @click.prevent="authStore.stage = authStage.WITH_PASSWORD"
        >
          Войти с постоянным паролем
        </span>
        <!-- Backend 3.0 -->
        <!-- <span
          v-else
          class="cursor-pointer text-xs lg:text-base"
          @click.prevent="authStore.stage = authStage.WITHOUT_PASSWORD"
        >
          Войти с одноразовым паролем
        </span> -->
      </div>
    </div>
    <p class="text-dnd_dark_grey text-xxs text-center lg:text-sm">
      Нажимая на кнопку «Продолжить», вы даете согласие на
      <a
        class="text-dnd_black hover:underline"
        href="https://api.prostoyvopros.ru/v1/file/1716968442170_.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        обработку персональных данных
      </a>
      и ознакомлены с
      <a
        class="text-dnd_black hover:underline"
        href="https://api.prostoyvopros.ru/v1/file/1703575015837___24.11.2023.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        Политикой в отношении обработки персональных данных
      </a>
    </p>
  </article>
</template>

<script setup>
import AuthWithPassword from '~/components/Auth/WithPassword.vue';
import AuthSMSCode from '~/components/Auth/SMSCode.vue';
import AuthWithoutPassword from '~/components/Auth/WithoutPassword.vue';
import AuthRegistration from '~/components/Auth/Registration.vue';
import AuthForgottenPassword from '~/components/Auth/ForgottenPassword.vue';
import { authStage } from '~/helpers/auth.js';

const authStore = useAuthStore();

const component = computed(() => {
  switch (authStore.stage) {
    case authStage.WITH_PASSWORD:
      return AuthWithPassword;
    case authStage.SMS_CODE:
      return AuthSMSCode;
    case authStage.WITHOUT_PASSWORD:
      return AuthWithoutPassword;
    case authStage.REGISTRATION:
      return AuthRegistration;
    case authStage.FORGOTTEN_PASSWORD:
      return AuthForgottenPassword;
    default:
      return AuthWithPassword;
  }
});
</script>
