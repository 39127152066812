<template>
  <h1 class="text-dnd_black text-center text-2xl lg:text-3xl">Авторизация</h1>
  <div class="flex w-full flex-col gap-3 lg:gap-4">
    <UiInput
      v-model="authStore.phoneNumber"
      title="Номер телефона"
      data-maska="+7 (9##) ### ##-##"
      inputmode="tel"
      :error="v$.phoneNumber.$error"
    />
    <UiInput
      v-model="password"
      title="Пароль"
      type="password"
      :error="v$.password.$error"
    />
    <div class="flex w-full max-lg:items-center">
      <UiCheckBox v-model="userAgreement" />
      <p class="text-xs md:text-sm lg:text-base">
        Я принимаю
        <a
          href="https://api.prostoyvopros.ru/v1/file/1723796172812__.pdf"
          rel="noopener noreferrer"
          target="_blank"
          class="underline"
        >
          Пользовательское соглашение
        </a>
      </p>
    </div>
    <UiButton
      type="primary"
      :loading="loadingBtn"
      :disabled="disabledBtn"
      @click="login"
    >
      Продолжить
    </UiButton>
  </div>
  <div class="flex w-full justify-between">
    <span
      class="hover:text-dnd_yellow text-dnd_black cursor-pointer text-center text-xs transition lg:text-base"
      @click="authStore.stage = authStage.FORGOTTEN_PASSWORD"
    >
      Забыли пароль?
    </span>
    <span
      class="hover:text-dnd_yellow text-dnd_black cursor-pointer text-center text-xs transition lg:text-base"
      @click="navigateTo('https://prostoyvopros.ru/vzyat-v-dolg/', { external: true })"
    >
      Зарегистрироваться
    </span>
    <!-- Backend 3.0 -->
    <!-- <span
      class="hover:text-dnd_yellow text-dnd_black mb-6 mt-5 cursor-pointer text-center text-xs transition lg:mb-6 lg:mt-1 lg:text-base"
      @click="authStore.stage = authStage.REGISTRATION"
    >
      Зарегистрироваться
    </span> -->
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { AuthRules } from '~/helpers/validator';
import { authStage } from '~/helpers/auth';

const { $login, $api } = useNuxtApp();

const route = useRoute();

const authStore = useAuthStore();
const notieStore = useNotieStore();
const authRules = AuthRules(authStore);

const { phoneNumber } = storeToRefs(authStore);
const password = ref('');
const userAgreement = ref(false);
const loadingBtn = ref(false);

const disabledBtn = computed(() => v$.value.$invalid);

const v$ = useVuelidate(authRules, {
  phoneNumber,
  password,
});

const login = async () => {
  if (await v$.value.$validate()) {
    if (userAgreement.value) {
      try {
        loadingBtn.value = true;
        await $login({
          phone: phoneNumber.value,
          password: password.value,
        });
      } catch (err) {
        loadingBtn.value = false;
        notieStore.addNotification({
          type: 'error',
          message: 'Введены некорректные данные',
        });
      }
    } else {
      notieStore.addNotification({
        type: 'warning',
        message: 'Необходимо принять пользовательское соглашение',
      });
    }
  } else {
    notieStore.addNotification({
      type: 'error',
      message: 'Корректно заполните все поля',
    });
  }
};

onMounted(() => {
  loadingBtn.value = false;
});

onUnmounted(() => {
  loadingBtn.value = false;
});
</script>
