<template>
  <h1 class="text-dnd_black text-2xl lg:text-3xl">Авторизация</h1>
  <div class="flex w-full flex-col gap-3 lg:gap-4">
    <span
      v-if="v$.phoneNumber.$error"
      class="text-dnd_red mb-5 text-center"
    >
      Указаны некорректные данные
    </span>
    <UiInput
      v-model="authStore.phoneNumber"
      title="Номер телефона"
      data-maska="+7 (9##) ### ##-##"
      :error="v$.phoneNumber.$error"
    />
    <UiButton
      type="primary"
      :disabled="disabledBtn"
      @click="goToSMSCode()"
    >
      Продолжить
    </UiButton>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { authStage } from '~/helpers/auth';
import { AuthRules } from '~/helpers/validator';

const authStore = useAuthStore();

const authRules = AuthRules(authStore);

const disabledBtn = computed(() => v$.value.$invalid);

const v$ = useVuelidate(authRules, authStore);

const goToSMSCode = async () => {
  if (await v$.value.$validate()) {
    authStore.stage = authStage.SMS_CODE;
  }
};
</script>
