<template>
  <span
    class="mr-auto cursor-pointer"
    @click="authStore.stage = authStage.WITH_PASSWORD"
  >
    <div class="flex flex-row">
      <UiIcon name="arrowCircle" />
      <p class="ml-2">Назад</p>
    </div>
  </span>
  <h1 class="text-dnd_black text-2xl lg:text-3xl">Получение нового пароля</h1>
  <div class="flex w-full flex-col gap-3 lg:gap-4">
    <UiInput
      v-model="authStore.phoneNumber"
      title="Номер телефона"
      data-maska="+7 (9##) ### ##-##"
      :error="v$.phoneNumber.$error"
    />
    <UiButton
      type="primary"
      :disabled="disabledBtn"
      :loading="isLoading"
      @click="getNewPassword(phoneNumber)"
    >
      Получить пароль
    </UiButton>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import * as Sentry from '@sentry/vue';
import { AuthRules } from '~/helpers/validator';
import { authStage } from '~/helpers/auth';

const { $api } = useNuxtApp();

const authStore = useAuthStore();
const notieStore = useNotieStore();

const isLoading = ref(false);
const disabledBtn = computed(() => v$.value.$invalid);

const { phoneNumber } = storeToRefs(authStore);

const authRules = AuthRules(authStore);
const v$ = useVuelidate(authRules, {
  phoneNumber,
});

const getNewPassword = async (phone) => {
  if (await v$.value.$validate()) {
    try {
      isLoading.value = true;

      const newUser = await $api.web().mailMetrikaDisabled({
        phone,
      });

      if (newUser.data.data.disabled_mail_metrica) {
        notieStore.addNotification({
          type: 'error',
          message: 'Попробуйте позже',
        });

        return;
      }

      await $api.web().generateNewPassword({
        phone,
      });

      notieStore.addNotification({
        type: 'success',
        message: 'Пароль отправлен в СМС',
      });

      authStore.stage = authStage.WITH_PASSWORD;
      Sentry.captureMessage('[ok] password reset');
    } catch (err) {
      notieStore.addNotification({
        type: 'error',
        message: 'Что-то пошло не так',
      });
    } finally {
      isLoading.value = false;
    }
  } else {
    notieStore.addNotification({
      type: 'error',
      message: 'Введите корректный номер',
    });
  }
};
</script>
